var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CCard',[_c('CCardBody',[_c('h4',[_vm._v(" Menus ")]),_c('CButton',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addElementToMenu()}}},[_vm._v("Add Element to Menu")]),_c('CDataTable',{attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"items-per-page":30,"pagination":""},scopedSlots:_vm._u([{key:"dropdown",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.dropdown)?_c('CIcon',{attrs:{"content":_vm.$options.arrowIcon}}):_vm._e()],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"up",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveUp( item.id )}}},[_vm._v("Move Up")])],1)]}},{key:"down",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveDown( item.id )}}},[_vm._v("Move Down")])],1)]}},{key:"show",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showMenu( item.id )}}},[_vm._v("Show")])],1)]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editMenu( item.id )}}},[_vm._v("Edit")])],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteMenu( item.id )}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }